<template>
  <div class="index-page container-fluid h-100">

    <div class="d-flex flex-column justify-content-between h-100">
      <div>

        <div class="image">
          <img src="~@/assets/img/order-finish-img.jpg" />
        </div>

        <div class="caption">
          Many thanks
        </div>

        <div class="description">
          One more awesome session is over. <br />
          Keep exercising with Boogy and save even more.
        </div>
      </div>

      <div class="buttons">
        <router-link to="/place/map" class="btn btn-form">
          Finish
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {

}
</script>

<style scoped>

.caption {
  margin-top: 30px;
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 30px;
}

.image {
  text-align: center;
  padding-top: 50px;
}

.image img {
  max-width: 400px;
  width: 100%;
}

.buttons {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 30px;
}

.btn-form {
  max-width: 370px;
}

</style>